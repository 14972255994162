import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Rodape extends Component {

  constructor(props) {
    super(props);
  }

  data_atual = new Date();

  render() {
    return (
      <footer className="footer footer-black bg-2 bg-overlay-black-90">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="social">
                <ul>
                  <li>
                    <a className="facebook" href="https://www.facebook.com/AbsolutaVeiculos.Patos">
                      facebook <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      className="twitter"
                      href="https://www.instagram.com/absolutaveiculos.patos/"
                    >
                      Instagram <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a className="pinterest" href="https://www.google.com/maps/place/ABSOLUTA+VE%C3%8DCULOS/@-18.5877422,-46.5170927,17z/data=!3m1!4b1!4m5!3m4!1s0x94ae8ad299aadfaf:0xa6d01fbae09eb9aa!8m2!3d-18.5877422!4d-46.514904">
                      Localização <i className="fa fa-map-marker"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row footer_info">
            <div className="col-lg-3 col-md-6 footer_conteudo">
              <div className="about-content">
                <img
                  className="img-fluid"
                  id="logo-footer"
                  src="images/logo-light.png"
                  alt=""
                />
                <p>
                A Absoluta Veiculos é uma empresa revendedora de veiculos localizada em Patos de Minas, atende o mercado regional com a mais 
                completa equipe de profissionais, focada sempre na melhoria do atendimento e em todas as prestações de serviços.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 footer_conteudo">
              <div className="usefull-link">
                <h6 className="text-white">Informações de contato</h6>
                <ul>
                  <li>
                    <a href="#">
                      <i className="fa fa-clock-o"></i>
                      Seg - Sex: 8:00 ás 18:00. Sab: 8:00 ás 12:00. Dom: Fechado.
                    </a>
                  </li>
                  <li>
                    <a href="https://www.google.com/maps/place/ABSOLUTA+VE%C3%8DCULOS/@-18.5877422,-46.5170927,17z/data=!3m1!4b1!4m5!3m4!1s0x94ae8ad299aadfaf:0xa6d01fbae09eb9aa!8m2!3d-18.5877422!4d-46.514904">
                      <i className="fa fa-map-marker"></i>
                      R. Maj. Gote, n. 95 - Centro, Patos de Minas - MG, CEP 38700-108
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-phone"></i>
                      (34) 3825-3333
                    </a>
                  </li>
                  <li>
                    <a href="https://api.whatsapp.com/send?phone=553438253333&text=Ol%C3%A1%2C%20vi%20seu%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20seu%20produtos.">
                      <i className="fa fa-whatsapp"></i>
                      Whatsapp
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-envelope"></i>
                      contato@absolutaveiculospatos.com.br
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="text-lg-left text-center">
                  <p>
                    ©Copyright 2022 Absoluta Veículos por
                    <a
                      href="https://intermedioveiculos.com.br/"
                      target="_blank"
                    > Intermédio Veículos Marketing Digital</a
                    >
                  </p>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-12">
                <ul className="list-inline text-lg-right text-center">
                  <li><a href="contact-02.html">Contato </a></li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </footer>

    );
  }
}

function retiraMascara(value, type = "texto") {
  var numero = '';
  if (value !== undefined) {
    numero = value.replace('_', '');
    if (type === "link") {
      numero = numero.replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
    }
  }
  return numero;
}

export default Rodape;